import React, { useContext } from "react";
import { Link } from "gatsby";

import GlobalContext from "../../context/GlobalContext";
import imgC from "../../assets/image/inner-page/png/video-bg.png";

const Video = () => {
  const gContext = useContext(GlobalContext);

  return (
    <div
      id="video"
      className="pt-md-9 pb-15 pb-md-18 pb-lg-25 pr-xs-10 pl-xs-10"
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-md-10 col-sm-11"
            data-aos="fade-right"
            data-aos-duration={500}
          >
            <div
              className="bg-images d-flex align-items-center justify-content-center max-w-540 py-18 py-sm-28 rounded-10 mx-auto"
              css={`
                background-image: url(${imgC});
              `}
            >
              <a
                href="/#"
                className="video-btn sonar bg-blue-3-op5 text-white circle-98 font-size-8"
                onClick={(e) => {
                  e.preventDefault();
                  // gContext.toggleVideoModal();
                }}
              >
                <i className="fa fa-play" />
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-10 col-sm-11">
            <div className="pt-9 pl-xl-16 text-center">
              <h2
                className="font-size-10 mb-8 letter-spacing-n83"
                data-aos="fade-up"
                data-aos-duration={300}
              >
                Kurzfilm <br />
                (coming soon🍿)
              </h2>
              <p
                className="font-size-6 mb-0 "
                data-aos="fade-up"
                data-aos-duration={500}
              >
                Eine Mandantin mit hohen Ansprüchen, ein Partner mit Nerven aus
                Stahl und ein flinker Konzipient zeigen, wie einfach die Arbeit
                mit konzierge sein kann.
              </p>
              <div className="mt-11" data-aos="fade-up" data-aos-duration={700}>
                <Link
                  to="/#"
                  className="btn bg-gradient-1 btn-text-white rounded-5"
                >
                  Zum Erstgespräch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
