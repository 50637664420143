import React from "react";
import imageOne from "../../assets/image/Screenshot_konzierge_kaufobjekt.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightDots,
  faBullseye,
  faGaugeHigh,
} from "@fortawesome/free-solid-svg-icons";

const Features = ({ className, ...rest }) => {

  return (
    <div id="features" className={className} {...rest}>
      <div className="container-wide pt-lg-32 pb-15 pb-md-18 pt-xs-18">
        <div className="row justify-content-center justify-content-lg-start">
          <div className="col-12">
            <div
              className="mb-0 pr-md-0 mb-10 text-center text-lg-left"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <h2 className="font-size-11">
                <div className="mb-5">
                <span className='highlight-initial'>
                    konzierge.
                  </span>
                </div>
                <div className="mb-5">Software von Jurist:innen.</div>
                <div className="mb-5">Für Jurist:innen.</div>
              </h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center justify-content-center text-lg-left">
          <div className="col-xl-6 col-md-12 order-1 mb-md-0 mb-16">
            <div className="">
              {/* Services */}
              <div className="row justify-content-center text-center-webkit">
                <div className="col-sm-6">
                  <div
                    className="pt-13 pt-lg-15"
                    data-aos="fade-up"
                    data-aos-delay={300}
                  >
                    <div className="square-60 bg-sunset shadow-light-green rounded-10 text-white font-size-9">
                      <FontAwesomeIcon icon={faArrowUpRightDots} />
                    </div>
                    <div className="mt-7 pr-xl-16">
                      <h4 className="font-size-7 mb-5">Performance</h4>
                      <p className="font-size-5 mb-0">
                        Erstellen Sie Verträge in Rekordzeit und beeindrucken
                        Sie Ihre Mandant:innen.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div
                    className="pt-13 pt-lg-15"
                    data-aos="fade-up"
                    data-aos-delay={300}
                  >
                    <div className="square-60 bg-konzierge-blue-2 shadow-dodger-blue-1 rounded-10 text-white font-size-9">
                      <FontAwesomeIcon icon={faBullseye} />
                    </div>
                    <div className="mt-7 pr-xl-16">
                      <h4 className="font-size-7 mb-5">Fokus</h4>
                      <p className="font-size-5 mb-0">
                        Weniger Zeit für repetitive Arbeiten bedeutet mehr Zeit
                        für Ihr Kerngeschäft.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div
                    className="pt-13 pt-lg-15"
                    data-aos="fade-up"
                    data-aos-delay={300}
                  >
                    <div className="square-60 bg-light-green shadow-sunset rounded-10 text-white font-size-9">
                      <i className="fa fa-check" />
                    </div>
                    <div className="mt-7 pr-xl-16">
                      <h4 className="font-size-7 mb-5">Qualität</h4>
                      <p className="font-size-5 mb-0">
                        Mit unseren Schnittstellen zum Grund- und Firmenbuch
                        vermeiden Sie menschliche Fehler und Zahlendreher.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div
                    className="pt-13 pt-lg-15"
                    data-aos="fade-up"
                    data-aos-delay={300}
                  >
                    <div className="square-60 bg-blue-3 shadow-dodger-blue-3 rounded-10 text-white font-size-9">
                      <FontAwesomeIcon icon={faGaugeHigh} />
                    </div>
                    <div className="mt-7 pr-xl-16">
                      <h4 className="font-size-7 mb-5">Energie</h4>
                      <p className="font-size-5 mb-0">
                        Repetition führt nachweislich zu Erschöpfung. Nutzen Sie
                        Ihre Konzentration lieber für komplexe Aufgabenstellungen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Services */}
            </div>
          </div>
          <div className="col-xl-6 col-sm-10 col-xs-12 justify-content-md-start mt-xl-15 mt-md-25 mt-8 order-2 border-blue">
            <div
              className="mb-10 mr-10 mb-lg-0"
              data-aos="fade-left"
              data-aos-delay={300}
            >
              <img
                className="max-w-115 rounded-left-10 border-left border-top border-bottom"
                src={imageOne}
                alt=""
              />
            </div>
            {/* <div className="img-2 rounded-10">
                <img className="w-100 w-lg-auto" src={imageTwo} alt="" />
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
