import React from "react";

// import img1 from "../../assets/image/home-4/company-logo/dark-logo-1.png";
// import imgL1 from "../../assets/image/home-4/company-logo/light-logo-1.png";
// import img2 from "../../assets/image/home-4/company-logo/dark-logo-2.png";
// import imgL2 from "../../assets/image/home-4/company-logo/light-logo-2.png";
// import img3 from "../../assets/image/home-4/company-logo/dark-logo-3.png";
// import imgL3 from "../../assets/image/home-4/company-logo/light-logo-3.png";
// import img4 from "../../assets/image/home-4/company-logo/dark-logo-4.png";
// import imgL4 from "../../assets/image/home-4/company-logo/light-logo-4.png";
// import img5 from "../../assets/image/home-4/company-logo/dark-logo-5.png";
// import imgL5 from "../../assets/image/home-4/company-logo/light-logo-5.png";
// import img6 from "../../assets/image/home-4/company-logo/dark-logo-6.png";
// import imgL6 from "../../assets/image/home-4/company-logo/light-logo-6.png";

import brand1 from "../../assets/image/kwr.png";
import brand2 from "../../assets/image/sms-logo-sm.png";
import brand3 from "../../assets/image/podov.png";
import brand4 from "../../assets/image/hk-logo.png";
import brand5 from "../../assets/image/alr_logo_complete.png";
import brand6 from "../../assets/image/schmelz.png";

const link1 = "https://www.kwr.at/"
const link2 = "https://www.sms.law/"
const link3 = "https://www.podovsovnik.at/"
const link4 = "https://www.herbstkinsky.at/"
const link5 = "https://www.alr.at/"
const link6 = "https://www.rechtampunkt.at/"

const SingleBrand = ({ image, link }) => {
  return (
    <>
      {/* Single Brand */}
      <a href={link} target="_blank" rel="noopener noreferrer" className="single-brand-logo mx-8 my-10">
        <img
          className="max-w-140"
          src={image}
          alt=""
          data-aos="fade-in"
          data-aos-once="true"
        />
      </a>
      {/* Single Brand */}
    </>
  );
};

const Companies = ({ className, ...rest }) => {

  const images = [
    { link: link1, img: brand1 },
    { link: link2, img: brand2 },
    { link: link3, img: brand3 },
    { link: link4, img: brand4 },
    { link: link5, img: brand5 },
    { link: link6, img: brand6 },
  ];
  
  return (
    <>
      {/* Company Section */}
      <div className={className} {...rest}>
        <div className="container-wide">
          <div className="d-flex align-items-center justify-content-center mb-8">
            <div className="border-top border-width-2" style={{ flex: 1 }}></div>
            <div className="font-size-5 text-center mx-3 heading-default-color">
              im Einsatz bei
            </div>
            <div className="border-top border-width-2" style={{ flex: 1 }}></div>
          </div>
          {/* Brand Logos */}
          <div className="brand-logo-small d-flex align-items-center justify-content-around flex-wrap">
            {images.map((item, index) => (
              <SingleBrand image={item.img} link={item.link} key={index} />
            ))}
          </div>
        </div>
      </div>
      {/* End Company Section */}
    </>
  );
};

export default Companies;
