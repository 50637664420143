import React from "react";
import { InlineWidget } from "react-calendly";

const Calendly = ({ className, ...rest }) => {
    return (
        <div className={`position-relative ${className}`} {...rest}>
        <div className=" d-flex align-items-center pt-23 pt-md-26 pt-lg-30 pb-8 pb-md-12 pb-lg-23">
                <div className="container">
                    {/* Section Title */}
                    <div className="row justify-content-center">
                        <div className="col-xl-12">
                            <div
                                className="mb-10 mb-lg-18 text-center"
                                data-aos="fade-up"
                                data-aos-duration={300}
                                data-aos-once="true"
                            >
                                <h2 className="font-size-11 mb-7">Kontakt</h2>
                    
                            </div>
                        </div>
                    </div>
                    {/* End Section Title */}
                    {/* Contact Form */}
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <InlineWidget styles={{"height": "700px"}} className="overflow-hidden" url="https://calendly.com/konzierge" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};
export default Calendly;
