import React from "react";
import { scroller } from "react-scroll";

const Faq = () => {
  const scrollTo = () => {
    scroller.scrollTo("contact", {
      duration: 1800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <div id="faq" className="pb-13 pb-md-28 pb-lg-32 px-xs-4">
      <div className="container">
        {/* Section Title */}
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9">
            <div className="text-center mt-32 mb-13 mb-lg-19">
              <h2 className="font-size-11 mb-24">FAQ</h2>
            </div>
          </div>
        </div>
        {/* End Section Title */}
        {/* Faqs */}
        {/* Faqs */}
        <div className="row justify-content-center">
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={400}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  Meine Verträge sind sehr individuell. Kann mir konzierge
                  überhaupt behilflich sein?
                </h4>
                <p className="font-size-5 text-justify pr-md-10 pr-lg-0 mb-0">
                  Auf jeden Fall! In konzierge können komplexe Sachverhalte und
                  Logiken abgebildet werden. Zweck von Vertragsautomatisierung
                  ist nicht, jeden denkmöglichen Fall zu erfassen, sondern eine
                  spürbare Entlastung bei wiederkehrenden Fällen herbeizuführen.
                  Wer Zeit bei der Erstellung des Erstentwurfs spart, hat mehr
                  Zeit für die Individualisierung von Verträgen/Schriftsätzen.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={400}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  Was passiert mit meinen Verträgen/Schriftsätzen? Verändert
                  konzierge meine Muster?
                </h4>
                <p className="font-size-5 text-justify pr-md-10 pr-lg-0 mb-0">
                  Wir programmieren Logiken in Ihre Muster ein und machen diese
                  hierdurch dynamisch. Sie können Ihre Muster jederzeit um
                  weitere Klauseln ergänzen. Sollten Änderungen an der Logik
                  erforderlich sein, stehen wir Ihnen zur Seite. Alle Dokumente,
                  die konzierge auf Basis Ihrer Muster generiert, behalten
                  Formatierung und Inhalt bei - ganz nach Ihren Vorgaben.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={400}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  Bedeutet effizienter zu sein, weniger Honorar verlangen zu
                  können?
                </h4>
                <p className="font-size-5 text-justify pr-md-10 pr-lg-0 mb-0">
                  Nein. konzierge schenkt Ihnen und Ihrem gesamten Team mehr
                  Zeit für die wichtigen Dinge, wie zB Individualisierung von
                  Verträgen/Schriftsätzen, Verhandlungsvorbereitung, Recherchen,
                  Mandantenakquise, etc. Was Sie mit der gewonnen Zeit machen,
                  bleibt natürlich Ihnen überlassen.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={400}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  Ist konzierge eine Cloud Lösung? Sind meine Daten sicher?
                </h4>
                <p className="font-size-5 text-justify pr-md-10 pr-lg-0 mb-0">
                  Ja und ja! konzierge ist ein sicherer Dienstleister, der
                  Cloud-Technologien einsetzt. Wir ziehen dabei nur
                  zertifizierte Technologien heran und setzen auf
                  State-of-the-Art Verschlüsselungsmethoden, um Ihre Daten und
                  jene Ihrer Mandant:innen DSGVO- und standeskonform zu
                  schützen.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
        </div>
        {/* Button  */}
        <div
          className="text-center pt-13 pb-lg-3"
          data-aos="fade-up"
          data-aos-duration={400}
        >
          <div className="font-size-7 mb-8">
            Ihre Frage wurde noch nicht beantwortet?
          </div>
          <button
            to="contact"
            onClick={() => scrollTo()}
            className="btn btn-outline-dark-cloud darkmode-btn btn-xl h-55 rounded-5"
          >
            Kontakt aufnehmen
          </button>
        </div>
        {/* Button End */}
      </div>
    </div>
  );
};

export default Faq;
