import React from "react";
import Slider from "react-slick";
import imgRS from "../../assets/image/home-6/png/l6-review-star.png";
import kwrLogo from "../../assets/image/kwr2.png";
import smsLogo from "../../assets/image/sms.png";
import podovLogo from "../../assets/image/podov1.png";
import alrLogo from "../../assets/image/alr.png";

const Testimonial = ({ className, ...rest }) => {
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          centerPadding: "20%",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          centerPadding: "0",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          {/* Section Title */}
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8 col-md-10">
              <div className="text-center pt-13 pb-13 pb-lg-12 pr-lg-10 pr-xl-0">
                <h2 className="font-size-11 mb-7">Referenzen</h2>
                <p className="font-size-7 mb-0 px-xl-10">
                  Das sagen Kunden über uns.
                </p>
              </div>
            </div>
          </div>
          {/* End Section Title */}
          <div className="row">
            <div className="col-12 px-xs-7 px-md-0">
              <Slider
                {...slickSettings}
                css={`
                  .slick-slide {
                    margin: 0 1rem;
                  }
                  .slick-dots {
                    display: flex !important;
                  }
                `}
                className="l6-testimonial"
              >
                {/* Single Testimonial */}
                <div className="single-testimoial bg-white border border-default-color-5 rounded-10 pt-5 pb-4 pt-sm-11 pt-md-11 pb-sm-10 pb-md-10 pl-5 pl-sm-11 pl-md-11 pr-6 pr-md-12 mx-md-0 focus-reset">
                  <div className="mb-10 mt-xs-4">
                    <img src={imgRS} alt="" />
                  </div>
                  <p className="font-size-65 mb-13 pr-sm-5 pr-md-0 pr-xl-4 text-dark-cloud">
                    Bei der geordneten Erstellung der Kauf- und
                    Treuhandunterlagen nicht mehr wegzudenken und daher unser
                    täglicher Begleiter seit mehr als 200 Immo-Deals.
                  </p>
                  <div className="d-flex align-items-center">
                    <div className="mr-6">
                      <img src={podovLogo} alt="" />
                    </div>
                    <div className="info">
                      <h5 className="font-size-65 text-dark-cloud mb-0">
                        Mag. Franz Podovsovnik
                      </h5>
                      <a
                        href="https://podovsovnik.at/"
                        className="font-size-5 text-stone mb-0"
                      >
                        podovsovnik.at
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Single Testimonial */}
                {/* Single Testimonial */}
                <div className="single-testimoial bg-white border border-default-color-5 rounded-10 pt-5 pb-4 pt-sm-11 pt-md-11 pb-sm-10 pb-md-10 pl-5 pl-sm-11 pl-md-11 pr-6 pr-md-12 mx-md-0 focus-reset">
                  <div className="mb-10 mt-xs-4">
                    <img src={imgRS} alt="" />
                  </div>
                  <p className="font-size-65 mb-13 pr-sm-5 pr-md-0 pr-xl-4 text-dark-cloud">
                    Mit konzierge wurde die Erstellung eines Vertragsentwurfes
                    wesentlich vereinfacht. Zeitintensive Recherchen werden
                    durch die Software miterledigt. Dies verschafft uns mehr
                    Zeit, um unsere Mandanten individuell und bestmöglich zu
                    beraten.
                  </p>
                  <div className="d-flex align-items-center">
                    <div className="mr-6">
                      <img src={smsLogo} alt="" />
                    </div>
                    <div className="info">
                      <h5 className="font-size-65 text-dark-cloud mb-0">
                        Mag. Markus Dax & <br />
                        Mag. Anna Woschitz
                      </h5>
                      <a
                        href="https://sms.law/"
                        className="font-size-5 text-stone mb-0"
                      >
                        sms.law
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Single Testimonial */}
                {/* Single Testimonial */}
                <div className="single-testimoial bg-white border border-default-color-5 rounded-10 pt-5 pb-4 pt-sm-11 pt-md-11 pb-sm-10 pb-md-10 pl-5 pl-sm-11 pl-md-11 pr-6 pr-md-12 mx-md-0 focus-reset">
                  <div className="mb-10 mt-xs-4">
                    <img src={imgRS} alt="" />
                  </div>
                  <p className="font-size-65 mb-13 pr-sm-5 pr-md-0 pr-xl-4 text-dark-cloud">
                    konzierge steht für reibungslose Digitalisierung ohne
                    Aufwand. Eine klare Empfehlung für jeden modernen
                    Kanzleibetrieb.
                  </p>
                  <div className="d-flex align-items-center">
                    <div className="mr-6">
                      <img src={kwrLogo} className="max-w-10" alt="" />
                    </div>
                    <div className="info">
                      <h5 className="font-size-65 text-dark-cloud mb-0">
                        Dr. Vinzenz Waldhof
                      </h5>
                      <a
                        href="https://www.kwr.at/"
                        className="font-size-5 text-stone mb-0"
                      >
                        kwr.at
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Single Testimonial */}
                {/* Single Testimonial */}
                <div className="single-testimoial bg-white border border-default-color-5 rounded-10 pt-5 pb-4 pt-sm-11 pt-md-11 pb-sm-10 pb-md-10 pl-5 pl-sm-11 pl-md-11 pr-6 pr-md-12 mx-md-0 focus-reset">
                  <div className="mb-10 mt-xs-4">
                    <img src={imgRS} alt="" />
                  </div>
                  <p className="font-size-65 mb-13 pr-sm-5 pr-md-0 pr-xl-4 text-dark-cloud">
                    konzierge ließ sich einfach in unsere Kanzleiprozesse
                    integrieren und beeindruckt uns seither mit Genauigkeit.
                    Jeder Vertrag erfüllt alle Anforderungen an Inhalt und
                    Formatierung.
                  </p>
                  <div className="d-flex align-items-center">
                    <div className="mr-6">
                      <img src={alrLogo} alt="" />
                    </div>
                    <div className="info">
                      <h5 className="font-size-65 text-dark-cloud mb-0">
                        Mag. Dr. Lukas Ludwiger
                      </h5>
                      <a
                        href="https://www.alr.at/"
                        className="font-size-5 text-stone mb-0"
                      >
                        alr.at
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Single Testimonial */}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
