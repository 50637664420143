import React, { useState, useEffect } from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/production/Hero";
import Features from "../sections/production/Features";
import Message from "../sections/production/Message";
import Video from "../sections/production/Video";
import Process from "../sections/production/Process";
import Team from "../sections/production/Team";
import Testimonial from "../sections/production/Testimonial";
import Companies from "../sections/production/Companies";
import Supporters from "../sections/production/Supporters";
import Faq from "../sections/production/Faq";
import CaseStudy from "../sections/production/CaseStudy";
import Calendly from "../sections/production/Calendly";
import { animateScroll as scroll, scroller } from "react-scroll";
import { FaArrowCircleUp } from "react-icons/fa";

const Landing = () => {
  useEffect(() => {
    // Function to handle intersection changes
    function handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.target.classList.contains("highlight-initial")) {
          if (entry.isIntersecting) {
            entry.target.classList.add("highlight");
          }
          // ONLY USE IF APPROVAL IS GIVEN BY CTO
          // else {
          //   entry.target.classList.remove("highlight");
          // }
        // } else if (entry.target.classList.contains("rocket-container-initial")) {
        //   if (entry.isIntersecting) {
        //     entry.target.classList.add("rocket-container");
        //   } else {
        //     entry.target.classList.remove("rocket-container");
        //   }
        }
      });
    }

    // Set up the Intersection Observer
    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 0.5, // Trigger when at least 50% of the element is in the viewport
    });

    // Observe the .highlight elements
    const observedElements = [];
    observedElements.push(...document.querySelectorAll(".highlight-initial"));
    observedElements.push(...document.querySelectorAll(".rocket-container-initial"));
    observedElements.forEach((element) => {
      observer.observe(element);
    });
  }, []);

  const scrollTo = (id) => {
    scroller.scrollTo(id, {
      duration: 1800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const ScrollButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setVisible(true);
      } else if (scrolled <= 300) {
        setVisible(false);
      }
    };

    const scrollToTop = () => {
      scroll.scrollToTop({
        duration: 1800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    };

    // check if is defined to avoid build error
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      window.addEventListener("scroll", toggleVisible);
    }

    return (
      <button
        className="scroll-up-button"
        style={{ display: visible ? "inline" : "none" }}
      >
        <FaArrowCircleUp onClick={scrollToTop} />
      </button>
    );
  };

  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <button
                to="contact"
                onClick={() => scrollTo("contact")}
                className="element btn bg-gradient-1 btn-text-white header-btn rounded-5"
              >
                Zum Erstgespräch
              </button>
            </>
          ),
        }}
      >
        <Hero
          className="pt-32 bg-default-3 h-screen grid content-center"
          style={{
            height: "100vh",
            display: "grid",
            "alignContent": "center",
          }}
        />
        <Features
          id="features"
          className="element pt-13 pt-lg-9 pb-9 pb-lg-13"
        />
        {/* <Video className="element	pt-lg-9" /> */}
        {/* <Benefits className="element bg-default-3 pt-13 pt-lg-25 pb-9 pb-lg-25" /> */}
        <Message id="message" />
        <Process
          id="integration"
          className="element bg-default-3 pt-13 pt-lg-24 pb-24 pb-lg-28"
        />
        <CaseStudy
          id="optimisation"
          className="element pt-13 pt-lg-24 pb-9 pb-lg-24"
        />
        <Testimonial
          id="reference"
          className="element bg-default-3 pt-13 pt-lg-24 pb-9 pb-lg-18"
        />
        <Companies className="element bg-default-3 pt-13 pb-9 pb-lg-18" />
        <Team
          id="team"
          className="element bg-default-3 pt-13 pt-lg-24 pb-9 pb-lg-18"
        />
        <Supporters className="element bg-default-3 pb-9 pb-lg-24" />
        <Faq className="element bg-default-3 pt-13 pt-lg-25 pb-9 pb-lg-25" />
        <Calendly
          id="contact"
          name="contact"
          className="element bg-default-3 pt-13 pt-lg-18 pb-9 pb-lg-25"
        />
        <ScrollButton />
      </PageWrapper>
    </>
  );
};
export default Landing;
