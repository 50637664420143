import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

const CaseStudy = ({ className, ...rest }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <div className={className} {...rest}>
      <div className="container-wide">
        {/* Section Title */}
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9">
            <div className="text-center pt-13 pb-13 pb-lg-12 pr-lg-10 pr-xl-0">
              <h2 className="font-size-11 mb-7">Effizienzgewinne</h2>
              <p className="font-size-7 px-lg-5 mb-7">
                Mehr Zeit für Individualisierung durch Automatisierung.
              </p>
            </div>
          </div>
        </div>
        {/* End Section Title */}
        <div className="row justify-content-around">
          {/* Single Table */}
          <div className="col-xl-5 col-s-12">
            <div
              className="border rounded-10 text-center px-7 pl-sm-12 pl-xs-3 pr-lg-16 pl-lg-14 pt-16 pb-16 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={200}
              data-aos-once="true"
            >
              <div className="square-60 bg-konzierge-blue-1 rounded-10 mb-10 shadow-bg-dodger-blue-2 text-white font-size-8 mx-auto">
                <i className="fa fa-building" />
              </div>
              <h3 className="text-dark-cloud font-size-85 mb-9">
                Wohnungskaufvertrag <br />
                mit konzierge
              </h3>
              <ol className="font-size-6 text-left line-height-50 heading-default-color pt-8 mb-12">
                <li className="mb-7">
                  Automatischer Import von Informationen aus dem Grundbuch
                </li>
                <li className="mb-7">
                  Käufer und Zahlungsdetails einmalig eingeben
                </li>
                <li className="mb-7">
                  Verkäufer- oder Käuferfreundlichkeit mit einem Mausklick
                  festlegen
                </li>
                <li className="mb-7">
                  Gesamte KV-Dokumentation (KV, <span>TH,</span>{" "}
                  <span>RO-Gesuch,</span> <span>Vollmacht,</span> KYC,{" "}
                  <span>E-Mails</span> & <span>Checklisten</span>) herunterladen
                </li>
                <li className="mb-7">Einmalige Revision des Vertragsinhalts</li>
              </ol>
              <div className="font-size-6 heading-default-color">
                Dauer: <span className="highlight-initial">3 Minuten</span>{" "}
                <span className="rocket-container-initial font-size-7">🚀</span>
              </div>
            </div>
          </div>
          {/* End Single Table */}

          {/* Single Table */}
          <div className="col-xl-5 col-xs-12">
            <div
              className="border rounded-10 text-center px-7 px-sm-12 px-xs-3 pr-lg-16 pl-lg-16 pt-16 pb-16 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={400}
              data-aos-once="true"
            >
              <div className="square-60 bg-konzierge-blue-1 rounded-10 mb-10 shadow-bg-dodger-blue-2 text-white font-size-8 mx-auto">
                <i className="fa fa-users" />
              </div>
              <h3 className="text-dark-cloud font-size-85 mb-9">
                Wohnungskaufvertrag manueller Prozess
              </h3>
              <ol className="font-size-6 text-left line-height-50 heading-default-color pt-8">
                <li className="mb-7">Grundbuchsauszug einholen</li>
                <li className="mb-7">
                  Daten von Eigentümer:in in Kaufvertrag eingegeben
                </li>
                <li className="mb-7">Daten von Käufer:in in KV eingeben</li>
                <li className="mb-7">
                  Anteile und B-LNR für Stellplätze hinzufügen
                </li>
                <li className="mb-7">Zuständiges Bezirksgericht ermitteln</li>
                <li id="notShowAllAnchorStep" className="mb-7">
                  Kopieren der Vertragsparteien in TH-Vereinbarung
                </li>
                <li className="mb-7">
                  Kopieren der Vertragsparteien in RO-Gesuch
                </li>
                <li className="mb-7">
                  Kopieren der Vertragsparteien in KYC-Dokumente
                </li>
                <li className="mb-7">
                  Belastungen aus dem Grundbuch in Kaufabrede ausformulieren
                </li>
                <li className="mb-7">
                  Suche nach geeigneten Gewährleistungsklauseln in ähnlichen
                  Verträgen
                </li>
                {showAll ? (
                  <div>
                    <li className="mb-7">
                      Kopieren und Anpassen von Gewährleistungsklauseln
                    </li>
                    <li className="mb-7">
                      GrESt & Eintragungsgebühr berechnen
                    </li>
                    <li className="mb-7">
                      Eidesstättige Erklärung (bei Ausländergrunderwerb) je nach
                      Bundesland formulieren
                    </li>
                    <li className="mb-7">
                      Aufschiebende Bedingungen (zB bei BVV) einfügen
                    </li>
                    <li className="mb-7">
                      Einzutragende Belastungen (zB Vorkaufsrechte) in
                      Aufsandungserklärung einpflegen
                    </li>
                    <li className="mb-7">
                      Bei 2 Käufern die Verbindung der Anteile beantragen
                    </li>
                    <li className="mb-7">
                      Zahlungsbedingungen (zB bei Löschung von Pfandrechten)
                      ergänzen
                    </li>
                    <li className="mb-7">
                      Formatierung überprüfen und anpassen
                    </li>
                    <li className="mb-7">
                      Vetragstext gendern und Einzahl/Mehrzahl kontrollieren
                    </li>
                    <li className="mb-7">Verweise überprüfen und anpassen</li>
                    <li className="mb-7">
                      Mehrmalige Revision des Vertragsinhalts
                    </li>
                    <li className="mb-7">
                      E-Mail an Vertragsparteien verfassen und Dokumente
                      anhängen
                    </li>
                    <div className="text-center">
                      <a
                        href="#notShowAllAnchorStep"
                        className="mt-7 cursor-pointer"
                        style={{ "marginLeft": "-50px" }}
                        onClick={() => setShowAll(false)}
                      >
                        <FontAwesomeIcon icon={faChevronUp} className="mr-2" />
                        Alle Schritte einklappen
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="text-center">
                    <a
                      className="mt-7 cursor-pointer"
                      style={{ "marginLeft": "-50px" }}
                      onClick={() => setShowAll(true)}
                    >
                      <FontAwesomeIcon icon={faChevronDown} className="mr-2" />
                      Alle Schritte anzeigen
                    </a>
                  </div>
                )}
              </ol>
              <div className="mt-12 text-center font-size-6 heading-default-color">
                Dauer: 30-45 Minuten ⌛
              </div>
            </div>
          </div>
          {/* End Single Table */}
        </div>
      </div>
    </div>
  );
};

export default CaseStudy;
