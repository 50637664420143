import React from 'react'
import { TypeAnimation } from 'react-type-animation';

import DashBoardImage from "../../assets/image/Screenshot_konzierge_hauptmenü.png";
import YellowShape from "../../assets/image/home-3/png/yellow-shape.png";
import GreenShape from "../../assets/image/home-3/png/big-green-circle-img2.png";

const Type = () => {
  return (
    <TypeAnimation
      cursor={true}
      sequence={[
        'Kaufverträge',
        2000,
        'Gesellschaftsverträge',
        2000,
        'Arbeitsverträge',
        2000,
        'Schriftsätze',
        2000
      ]}
      wrapper="h1"
      repeat={Infinity}
    />
  );
};

const Hero = ({ className, scrollToContact, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container-wide px-until-sm-1vw">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-7 col-md-9">
            <div
              className="text-center mb-11 mb-lg-24"
              style={{"marginTop": "-100px"}}
              data-aos="zoom-in"
              data-aos-delay={300}
            >
              <h1 className="font-size-sm-11 mb-0">
                Nutzen Sie das <br />gesamte Potential Ihrer
              </h1>
              <Type className="font-size-11 mb-7 "/>
              <p className="font-size-7 px-lg-5 mb-0">
                Individuelle Verträge brauchen individuelle Lösungen.
              </p>
            </div>
          </div>
        </div>
        {/* Hero Image Group */}
        <div className="row justify-content-center">
          <div className="col-md-10 col-11">
            <div className="l3-hero-image-group w-100">
              {/* Image One */}
              <div
                className="img-1 shadow-13"
                data-aos="fade-up"
                data-aos-delay={300}
                data-aos-once="true"
              >
                <img className='rounded-top-10' src={DashBoardImage} alt="" />
                {/* Image Two */}
                <div className="img-2">
                  <img
                    src={YellowShape}
                    alt=""
                    data-aos="fade-right"
                    data-aos-delay={700}
                    data-aos-once="true"
                  />
                </div>
                {/* Image Three */}
                <div className="img-3">
                  <img
                    src={GreenShape}
                    alt=""
                    data-aos="fade-left"
                    data-aos-delay={900}
                    data-aos-once="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Hero Image Group */}
      </div>
    </div>
  );
}


export default Hero;