import React from "react";

import imgA1 from "../../assets/image/home-2/png/arrow-1.png";
import imgA2 from "../../assets/image/home-2/png/arrow-2.png";
import imgR from "../../assets/image/home-2/png/right-layer.png";
import { scroller } from "react-scroll";

const Process = ({ className, ...rest }) => {
  const scrollTo = () => {
    scroller.scrollTo("contact", {
      duration: 1800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <>
      <div className={className} {...rest}>
        <div className="container-wide">
          {/* <!-- Section Title --> */}
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-9 col-xs-10">
              <div className="text-center pt-13 pb-13 pb-lg-12 pr-lg-10 pr-xl-0">
                <h2 className="font-size-11 mb-7">Integration</h2>
                <p className="font-size-7 px-lg-5 mb-12">
                  konzierge integriert sich reibungslos in Ihren Arbeitsalltag.
                </p>
              </div>
            </div>
          </div>
          {/* <!-- Section Title --> */}
          {/* <!-- Progress Items --> */}
          <div className="row justify-content-center">
            <div className="timeline-area d-flex flex-wrap flex-lg-nowrap position-relative ml-lg-28 px-10">
              {/* <!-- Image Group --> */}
              <div className="image-group-3">
                <div
                  className="arrow-shape-1 d-none d-lg-block absolute-top-left"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  data-aos-once="true"
                >
                  <img src={imgA1} alt="" />
                </div>
                <div
                  className="arrow-shape-2 d-none d-lg-block absolute-top-right"
                  data-aos="zoom-in"
                  data-aos-delay="400"
                  data-aos-once="true"
                >
                  <img src={imgA2} alt="" />
                </div>
              </div>
              {/* <!-- Single Progress --> */}
              <div
                className="single-widgets mr-lg-10 pr-lg-10 pl-lg-10 mb-22 mb-lg-0 text-center text-lg-left"
                data-aos="zoom-in"
                data-aos-delay="0"
                data-aos-once="true"
              >
                <div className="square-97 bg-konzierge-blue-2 rounded-10 mb-10 shadow-bg-dodger-blue-2 text-white font-size-10 mx-auto mx-lg-0">
                  <i className="fa fa-calendar-check" />
                </div>
                <div className="">
                  <h3 className="font-size-8 mb-6">1. Erstgespräch</h3>
                  <p className="font-size-5 line-height-28 mb-0">
                    In einem unverbindlichen Gespräch identifizieren wir
                    konkrete Anwendungsfälle.
                  </p>
                </div>
              </div>
              {/* <!-- End Single Progress --> */}
              {/* <!-- Single Progress --> */}
              <div
                className="single-widgets pr-lg-0 mb-22 mb-lg-0 text-center text-lg-left"
                data-aos="zoom-in"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <div className="square-97 bg-konzierge-blue-2 rounded-10 mb-10 shadow-bg-dodger-blue-2 text-white font-size-10 mx-auto mx-lg-0">
                  <i className="fa fa-file-word" />
                </div>
                <div className="">
                  <h3 className="font-size-8 mb-6">2. Muster einspielen</h3>
                  <p className="font-size-5 line-height-28 mb-0">
                    Wir integrieren Ihre Kanzleimuster in konzierge und
                    individualisieren das User Interface.
                  </p>
                </div>
              </div>
              {/* <!-- End Single Progress --> */}
              {/* <!-- Single Progress --> */}
              <div
                className="single-widgets pr-lg-18 pr-lg-0 mb-22 mb-lg-0 text-center text-lg-left"
                data-aos="zoom-in"
                data-aos-delay="400"
                data-aos-once="true"
              >
                <div className="square-97 bg-konzierge-blue-2 rounded-10 mb-10 shadow-bg-dodger-blue-2 mx-auto mx-lg-0">
                  <img src={imgR} alt="" />
                </div>
                <div className="">
                  <h3 className="font-size-8 mb-6">3. Loslegen</h3>
                  <p className="font-size-5 line-height-28 mb-0">
                    Sie holen sich wertvolle Zeit zurück und beeindrucken
                    Mandant:innen und Kolleg:innen.
                  </p>
                </div>
              </div>
              {/* <!-- End Single Progress --> */}
            </div>
          </div>
          {/* <!-- End Progress Items --> */}
          <div
            className="mt-lg-13 text-center"
            data-aos="fade-up"
            data-aos-delay={300}
            data-aos-once="true"
          >
            <button
              to="contact"
              onClick={() => scrollTo()}
              className="btn px-7 btn-outline-dark-cloud darkmode-btn btn-xl h-55 rounded-5"
            >
              Erstgespräch vereinbaren
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Process;
