import React from "react";

const Metaphor = (props) => {
  return (
    <div className="pt-24 pb-32">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6 col-md-7 col-xs-10">
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-duration={200}
            >
              <h2 className="font-size-10 letter-spacing-n83 line-height-100 vh-40">
                Ihr gesamtes Vertragswissen an einem <span className="highlight-initial line-height-90">einzigen Ort.</span>
              </h2>
            </div>
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-duration={200}
            >
              <h2 className="font-size-10 letter-spacing-n83 line-height-100 vh-40">
                Die passende Klauselvariante <span className="highlight-initial line-height-90">immer griffbereit.</span>
              </h2>
            </div>
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-duration={200}
            >
              <h2 className="font-size-10 letter-spacing-n83 line-height-100 vh-40">
                Verträge erstellen, war noch nie so <span className="highlight-initial line-height-90">einfach.</span>
              </h2>
            </div>
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-duration={200}
            >
              <h2 className="font-size-10 letter-spacing-n83 line-height-100">
                <span className="highlight-initial line-height-90">konzierge.</span>
              </h2>
            </div>
          </div>
        </div>
        {/* End Section title */}
      </div>
    </div>
  );
};

export default Metaphor;
